import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Login from "../../components/member/Login"
import FrontContainer from "../../components/member/FrontContainer"

export default () => {
  const pageSlug = "login"
  const pageTitle = "メンバーログイン"

  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "member/cover/bg_login.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const coverImage = data.coverImage.childImageSharp.fluid

  return (
    <FrontContainer
      pageSlug={pageSlug}
      pageTitle={pageTitle}
      coverImage={coverImage}
    >
      <Login />
    </FrontContainer>
  )
}

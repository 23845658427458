import { Link } from "gatsby"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import firebase from "../../utils/firebase"
import React, { useEffect, useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import CircularProgress from "@material-ui/core/CircularProgress"
import styles from "../../assets/scss/member/login.module.scss"

export default props => {
  const [email, setEmail] = useState("")
  const [errorEmail, setErrorEmail] = useState("")

  const [errorPassword, setErrorPassword] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  // componentDidMount
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(u) {
      if (u && u.emailVerified) {
        window.location.replace("/member")
      }
    })
  }, [])

  const validateEmail = e => {
    if (email === "") {
      setErrorEmail("入力してください。")
      return false
    } else {
      if (email.length >= 100) {
        setErrorEmail("長すぎます。100文字まで")
        return false
      }
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i
      if (email.match(re) === null) {
        setErrorEmail("メールフォーマットが違います。")
        return false
      }
    }
    setErrorEmail("")
    return true
  }

  const validatePassword = e => {
    if (password === "") {
      setErrorPassword("パスワードを入力してください")
      return false
    } else {
      if (email.length >= 100) {
        setErrorPassword("100文字までで設定してください")
        return false
      }
      if (email.length < 6) {
        setErrorPassword("6文字以上で設定してください")
        return false
      }
    }
    setErrorPassword("")
    return true
  }

  const handleSignIn = e => {
    e.preventDefault()
    let resValidateEmail = validateEmail()
    let resValidatePassword = validatePassword()

    if (!resValidateEmail || !resValidatePassword) return
    setLoading(true)
    ;(async (arg_email, arg_password) => {
      let res = await firebase
        .auth()
        .signInWithEmailAndPassword(arg_email, arg_password)
        .catch(function(error) {
          let errorCode = error.code
          let errorMessage = error.message
          if (errorCode === "auth/wrong-password") {
            setErrorPassword("Wrong password.")
          } else {
            setErrorPassword(errorMessage)
          }
        })
      setLoading(false)
      if (!res || !res.user) return
      window.location.replace("/member")
    })(email, password)
  }
  return (
    <div className={styles.root}>
      <Dialog open={loading}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <div>
        <div className={styles.heading}>
          <Typography component="h1" variant="h5">
            QULA
          </Typography>
          <Typography>メンバーログイン</Typography>
        </div>
        <form onSubmit={handleSignIn}>
          <div className={styles.elem}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              error={errorEmail !== ""}
              helperText={errorEmail}
              onBlur={validateEmail}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
          </div>
          <div className={styles.elem}>
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              type="password"
              value={password}
              error={errorPassword !== ""}
              helperText={errorPassword}
              onBlur={validatePassword}
              onChange={e => {
                setPassword(e.target.value)
              }}
            />
          </div>
          <div className={styles.submit}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={handleSignIn}
              size="large"
              fullWidth
            >
              ログイン
            </Button>
            <Typography>
              <Link to="/member/signup" className={styles.signup_link}>
                新規メンバー登録
              </Link>
            </Typography>
          </div>
        </form>
      </div>
    </div>
  )
}
